// Typography
$font-family-sans-serif: 'Nunito', sans-serif;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #eed814;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #9FB6DE;
$lightgrey: #F7F7F7;
$warning: #ffbb00;

// Leadtrekker colors
$lt-red: #EF3C4A;
$lt-light-red: #eb989f;
$lt-light-blue: #9FB6DE;
$lt-blue: #5B73B7;
$lt-dark-blue: #234A8D;

// theme colors
$primary: #244B90;

// remove input glow on focus effect
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0) inset, 0 0 8px rgba(126, 239, 104, 0);
  outline: 0 none;
}
