// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font Awesome
@import "node_modules/font-awesome/scss/font-awesome.scss";

// jquery-ui
@import '~jquery-ui/themes/base/all.css';

.custom-switch.custom-switch-lg {
    padding-bottom: 1rem;
    padding-left: 2.25rem;

    & .custom-control-label {
        padding-left: .75rem;
        padding-top: 0.15rem;

        &::before {
            border-radius: 1rem;
            height: 1.5rem;
            width: 2.5rem;
        }

        &::after {
            border-radius: .65rem;
            height: calc(1.5rem - 4px);
            width: calc(1.5rem - 4px);
        }
    }

    .custom-control-input:checked~.custom-control-label::after {
        transform: translateX(1rem);
    }
}

/**
* Add some breathing space above our footer and to the top of our content
*/
.content {
    padding-top: 20px !important;
    padding-bottom: 5px !important;
}

.dropdown-header {
    text-align: left !important;
    color: $primary;
    font-weight: 600;
}

.breadcrumb {
    border: 1px solid #D1D3D5;
}

/**
* Our administrator section on the dashboard only Starbright / Leadtrekker can see
*/
#LeadtrekkerAdministratorDashboard h5,
h2 {
    font-weight: bold;
}

#LeadtrekkerAdministratorDashboard .progress {
    height: 2.0rem;
}

#LeadtrekkerAdministratorDashboard .progress-bar {
    font-size: 1.2em;
    font-weight: 600;
}

.dashboardXeroImage {
    width: 40px;
    display: inline;
    margin-right: 30px;
    padding-top: 5px;
}

.dashboardXeroContainer {
    display: block;
    padding: 7px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border: 2px solid #e6e4e4;
    background-color: #FFFFFF;
}

.moreFeaturesImage {
    width: 70px;
    margin-top: 10px;
}

/**
* Leadtrekker custom colors
*/
.lt-red {
    color: $lt-red;
}

.lt-light-red {
    color: $lt-light-red;
}

.lt-blue {
    color: $lt-blue;
}

.lt-bg-dark-blue {
    background-color: $lt-dark-blue;
}

.lt-bg-light-blue {
    background-color: $lt-light-blue;
}

.interactable {
    cursor: pointer;
}

/**
* Ends admistrator sectrion
*/

/**
* Navbar search
*/
// set a wider width on larger screens
@media (min-width: 992px) {
    input[name="adminlteSearch"] {
        width: 600px !important;
    }
}

.ui-menu-item .ui-menu-item-wrapper.ui-state-active {
    background-color: #244B90 !important;
    border: 1px solid #244B90 !important;
}

.ui-autocomplete {
    padding-top: 20px !important;
}

// Ends navbar

footer {
    font-size: 90%;
}

.card-title {
    margin-bottom: 0px !important;
}

input:focus {
    outline: none;
}

validation {
    color: $danger;
    display: block;
    margin-top: 0px;
}

.sidebar .nav-link .fa-angle-left {
    color: $white;
    margin-right: inherit !important;
}

.sidebar .nav-link i {
    margin-right: 15px;
}

.active-client {
    color: #FFFFFF !important;
}

.active-client img {
    width: 17px !important;
    margin-right: 20px;
}
